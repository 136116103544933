import React from 'react';

function Account() {
    return (
        <div>
            <h1>Your Account</h1>
            <p>Account details</p>
        </div>
    );
}

export default Account;
