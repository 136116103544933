import React, { useState } from 'react';
import './AuthPopup.css';

const AuthPopup = ({ onClose }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Пример функции loginUser
    const loginUser = async (username, password) => {
        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('jwtToken', data.token);
                return data.token;
            } else {
                alert(data.message || 'Ошибка авторизации');
                return null;
            }
        } catch (error) {
            console.error('Ошибка авторизации:', error);
            return null;
        }
    };

    const handleLogin = async () => {
        const token = await loginUser(username, password);
        if (token) {
            onClose();
        }
    };

    // Пример функции registerUser
    const handleRegister = async () => {
        try {
            const response = await fetch('/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json();
            if (response.ok) {
                alert('Регистрация успешна!');
                setIsLogin(true);
            } else {
                alert(data.message || 'Ошибка регистрации');
            }
        } catch (error) {
            console.error('Ошибка регистрации:', error);
        }
        onClose();
    };

    const toggleForm = () => {
        setIsLogin(!isLogin);
    };

    return (
        <div className="auth-popup">
            <div className="auth-popup-content">
                <span className="close-btn" onClick={onClose}>✖</span>
                {isLogin ? (
                    <>
                        <h2>Войти</h2>
                        <input
                            type="text"
                            placeholder="Имя пользователя"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Пароль"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button onClick={handleLogin}>Войти</button>
                        <p>Нет аккаунта? <span onClick={toggleForm}>Зарегистрируйтесь</span></p>
                    </>
                ) : (
                    <>
                        <h2>Регистрация</h2>
                        <input
                            type="text"
                            placeholder="Имя пользователя"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Пароль"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button onClick={handleRegister}>Зарегистрироваться</button>
                        <p>Уже есть аккаунт? <span onClick={toggleForm}>Войти</span></p>
                    </>
                )}
            </div>
        </div>
    );
};

export default AuthPopup;
