import React from 'react';
import './Home.css';  // подключение файла стилей

function Home() {
  return (
    <div className="home">
      <header className="home-header">
        <h1>Welcome to SRS Store</h1>
        <p>Explore the best products at unbeatable prices</p>
      </header>
      <section className="home-products">
        <div className="product-card">Product 1</div>
        <div className="product-card">Product 2</div>
        <div className="product-card">Product 3</div>
        {/* Add more products as needed */}
      </section>
    </div>
  );
}

export default Home;
