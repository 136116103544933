import React from 'react';
import './Catalog.css';

function Catalog() {
  return (
    <div className="catalog">
      <header className="catalog-header">
        <h1>Product Catalog</h1>
      </header>
      <div className="catalog-filter">
        <select>
          <option>All Categories</option>
          <option>Category 1</option>
          <option>Category 2</option>
        </select>
        <select>
          <option>Sort by Price</option>
          <option>Low to High</option>
          <option>High to Low</option>
        </select>
      </div>
      <section className="catalog-products">
        <div className="product-item">Product 1</div>
        <div className="product-item">Product 2</div>
        <div className="product-item">Product 3</div>
        {/* Add more products as needed */}
      </section>
    </div>
  );
}

export default Catalog;
